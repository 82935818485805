import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

// Dodane testowo slajdy z rozdziału pierwszego!!!
// TODO: zmienić na właściwe slajdy
import Slide1 from 'src/slides/desktop/ru/chapter_3/sub_1/slide1';
import Slide2 from 'src/slides/desktop/ru/chapter_3/sub_1/slide2';
import Slide3 from 'src/slides/desktop/ru/chapter_3/sub_1/slide3';
import SEO from 'src/components/_shared/seo';

import WrapperMobile from 'src/slides/mobile/ru/chapter_3/sub_1/wrapperMobile';
import { isMobile } from 'react-device-detect';

const allSlides = [<Slide1 />, <Slide2 />, <Slide3 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Восстановление независимости Польши в 1918 г. I Варшавская битва" lang="ru" description="История возрождения польского государства после 123 лет неволи." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Восстановление независимости Польши в 1918 г. I Варшавская битва" lang="ru" description="История возрождения польского государства после 123 лет неволи." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
