import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_3/sub_1/slide3';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      leftColumn: mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-3-1-3"}}) {
        body
      },
      audio1: mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-3-1-3-audio1"}}) {
        excerpt(pruneLength: 100000)
      },
      audio2: mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-3-1-3-audio2"}}) {
        excerpt(pruneLength: 100000)
      },
    }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
