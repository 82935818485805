import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_3/sub_1/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-3-1-1"}}) {
      body
    }
  }
  `);
  return (
    <Template query={query} title="Часть I" />
  );
};


export default Slide;
